Vue.component('homeItemComponent', {
    props:['itemCode'],
    data:()=>{
        return {
            Qty:1,
            item:null
        }
    },
    watch: {
        '$route':'forceUpdate',
        '$store.state.itemTimeFlag':'forceUpdate'
    },
    methods:{
        forceUpdate:function () {
            //console.log('Refresh from homeItemComponent');
            this.item =null;
            this.item = this.$store.getters.getItemByCode(this.itemCode)
        },
        goBack:function(){
            this.$router.go(-1);
        },
        openItemView:function(item){
            if(item)
                this.$router.push(`/items/${item.Code}`);
        },
        ItemComponentAddToCar:function(){
            if(isNaN(this.Qty) || this.Qty==0) {
                this.Qty = 0;
                return;
            }
            if(this.item.carCant)
                this.item.carCant=parseInt(this.item.carCant) +parseInt(this.Qty);
            else
                this.item.carCant=this.Qty;
            this.$store.dispatch('ItemComponentAddToCar',this.item);
        },
        itemQtyIncrement:function(){
            if(Number.isInteger(this.item.carCant))
                this.Qty += 1;
            else
                this.Qty=1;

        },
        itemQtyDecrement:function(){
            if(this.item.carCant && this.item.carCant>1)
                this.Qty -= 1;
            else
                this.Qty=0;
        }
    },
    template:`<li class="col l3 m6 s12" v-if="item">
                  <itemViewModuleComponent :item="item"></itemViewModuleComponent>
              </li>`
});
